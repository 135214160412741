import request from '@/util/request';

/* 正在直播中的列表 */
export function getLivinglist(params) {
  return request({
    url: '/qiniu/pili/living_list',
    method: 'post',
    data: params,
  });
}

/* 申请开播 */
export function onApplicationForBroadcasting(params) {
  return request({
    url: '/live/anchor/apply',
    method: 'get',
    params,
  });
}

/* 开播设置 */
export function setConfig(params) {
  return request({
    url: '/live/action/config',
    method: 'post',
    data: params,
  });
}

/* 直播回放列表 */
export function queryReplaylist(params) {
  return request({
    url: '/live/replay/list',
    method: 'post',
    data: params,
  });
}

/* 直播回放删除 */
export function queryReplayDelete(params) {
  return request({
    url: '/live/replay/delete',
    method: 'post',
    params: params,
  });
}

/* 直播回放置顶 */
export function queryReplayStick(params) {
  return request({
    url: '/live/replay/stick',
    method: 'post',
    params,
  });
}

/* 主播列表 */
export function queryAnchorlist(params) {
  return request({
    url: '/live/anchor/list',
    method: 'get',
    params,
  });
}

/* 主播详情 */
export function queryAnchorDetail(params) {
  return request({
    url: '/live/anchor/anchor_detail',
    method: 'get',
    params,
  });
}

/* 查看公告列表 */
export function queryNotice(params) {
  return request({
    url: '/live/notice',
    method: 'get',
    params,
  });
}

/**
 * 直播详情
 * @param {liveAnchorId} params
 * @returns
 */
export function livingDetail(params) {
  return request({
    url: '/qiniu/pili/living_detail',
    method: 'get',
    params,
  });
}

/**
 * 直播详情
 * @param {liveAnchorId} params
 * @returns
 */
export function commentList(params) {
  return request({
    url: '/live/comment/list',
    method: 'post',
    data: params,
  });
}
/* 新增公告列表 */
export function addQueryNotice(params) {
  return request({
    url: '/live/notice',
    method: 'post',
    data: params,
  });
}

/* 删除公告列表 */
export function delNotice(params) {
  return request({
    url: '/live/notice',
    method: 'delete',
    params,
  });
}
/* 修改公告列表 */
export function updateNotice(params) {
  return request({
    url: '/live/notice',
    method: 'put',
    data: params,
  });
}
/* 查看关注 */
export function fansFollow(params) {
  return request({
    url: '/live/fans/follow',
    method: 'POST',
    data: params,
  });
}

/* 停播公告列表 */
export function queryShutdown(params) {
  return request({
    url: '/live/shutdown',
    method: 'get',
    params,
  });
}
/**
 * 发邮件
 * @param {*} params
 * @returns
 */
export function sendResume(params) {
  return request({
    url: '/live/mail/send_resume',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function watchDuration(params) {
  return request({
    url: '/live/behavior/watch_duration',
    method: 'post',
    params: params,
  });
}

/**
 * 主播置顶
 * @param {liveAnchorId} params
 * @returns
 */
export function anchorStick(params) {
  return request({
    url: '/live/anchor/stick',
    method: 'post',
    params,
  });
}

/**
 * 发表评论
 * @param {commentType: 1,
            content,
            sourceId: this.$route.params.id,
            userId: this.userId,
            userRoleType: 1} data
 * @returns
 */
export function commentCreate(data) {
  return request({
    url: '/live/comment/create',
    method: 'post',
    data,
  });
}

/**
 * 在线直播实时信息
 * @param {*} params
 * @returns
 */
export function onlineDetail(params) {
  return request({
    url: '/live/anchor/live/online_detail',
    method: 'get',
    params,
  });
}

/**
 * 直播预告置顶
 * @param {*} params
 * @returns
 */
export function onNoticeStick(params) {
  return request({
    url: '/live/notice/stick',
    method: 'post',
    params,
  });
}

/**
 * 区域
 * @param {*} params
 * @returns
 */
export function getAreas(params) {
  return request({
    url: '/api/sys/sys-area/areas',
    method: 'post',
    data: params,
  });
}
/**
 * 学校
 * @param {*} params
 * @returns
 */
export function getCity(params) {
  return request({
    url: '/api/school/sch-info/list/city',
    method: 'post',
    data: params,
  });
}
/**
 * 根据父id查询子级城市信息
 * @param {*} params
 * @returns
 */
export function getAreaPage(params) {
  return request({
    url: '/api/sys/sys-area/getAreaPage',
    method: 'get',
    params,
  });
}

/**
 * 观看回放
 * @param {id} params
 * @returns
 */
export function replayWatch(params) {
  return request({
    url: '/live/replay/watch',
    method: 'get',
    params,
  });
}

/**
 * 直播赠送E豆子
 * @param {amount, audienceUserId, liveAnchorId, liveId} data
 * @returns
 */
export function liveBalanceLottery(data) {
  return request({
    url: '/live/balance/lottery',
    method: 'post',
    data,
  });
}

/**
 * 修改直播间名称
 * @param {liveRoomName, liveAnchorId} data
 * @returns
 */
export function updateAnchorName(data) {
  return request({
    url: '/live/anchor/update',
    method: 'post',
    data,
  });
}

/**
 * 查询企业信息
 * @param {liveRoomName, liveAnchorId} data
 * @returns
 */
export function queryComHome(params) {
  return request({
    url: '/live/open/comHome',
    method: 'get',
    params,
  });
}

/**
 * 企业发布岗位
 * @param {liveRoomName, liveAnchorId} data
 * @returns
 */
export function getProfessionList(params) {
  return request({
    url: '/live/open/getProfessionList',
    method: 'get',
    params,
  });
}

/**
 * 企业发布岗位详情
 * @param {liveRoomName, liveAnchorId} data
 * @returns
 */
export function getProfession(params) {
  return request({
    url: '/api/appstudent/index/getProfession',
    method: 'get',
    params,
  });
}

/**
 * 企业发布岗位详情推荐
 * @param {liveRoomName, liveAnchorId} data
 * @returns
 */
export function getSameTypeRecommend(params) {
  return request({
    url: '/api/appstudent/index/getSameTypeRecommend',
    method: 'get',
    params,
  });
}

/**
 * 企业信息 更多信息
 * @param
 * @returns
 */
export function getCompanyInfo(params) {
  return request({
    url: '/live/open/getCompanyInfo',
    method: 'get',
    params,
  });
}

/**
 * 直播观看
 */
export function liveWatch(params) {
  return request({
    url: '/live/anchor/live/watch',
    method: 'get',
    params,
  });
}

/**
 * 直播观看人数
 */
export function liveWatchCount(params) {
  return request({
    url: '/live/anchor/live/watch_count',
    method: 'get',
    params,
  });
}

export function liveWatchData(params) {
  return request({
    url: '/live/anchor/live/real_time_data',
    method: 'get',
    params,
  });
}

/**
 * 直播离开
 */
export function liveLeave(params) {
  return request({
    url: '/live/anchor/live/leave',
    method: 'get',
    params,
  });
}
