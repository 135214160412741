<template>
  <div class="homesharing">
    <div class="header">
      <span>
        <span style="font-size: 16px">{{ liveData.liveRoomName }}</span>
        <a-icon
          v-if="isAnchor"
          @click="handleEditName"
          class="editIcon"
          type="edit"
        />
      </span>
      <div class="count-wrapper">
        <span>观看人数：{{ count }}</span>
      </div>
    </div>
    <div class="container" ref="container" style="width: 100vw">
      <div class="no-video">
        {{ loading ? '加载中...' : '该主播当前不在直播!' }}
      </div>
    </div>
    <div class="share-wrapper">
      <CompanyShare
        :companyId="this.$route.query.companyId"
        :liveAnchorLiveId="this.$route.query.liveAnchorLiveId"
      ></CompanyShare>
    </div>
    <!-- <div class="comment-wrapper outCompoment">
      <el-row type="flex" class="row-bg" justify="center"
        ><el-col justify="center" class="title_left"
          ><span @click="nextxiangqing()">公司详情</span></el-col
        ><el-col justify="center" class="title_right"
          ><span @click="nextxiangqing()">公司简介</span></el-col
        ></el-row
      >
    </div> -->
    <div class="bottom_wrap">
      <div class="bottom_top">
        <span>在APP内打开，查看更多内容</span>
      </div>
      <div class="bottom_bottom">
        <div class="btn" @click="toApp">在APP内打开</div>
      </div>
    </div>

    <div class="yindao" @click="hideShowDownload" v-if="showDownload">
      <img
        class="yindao_img"
        :src="require('@/assets/images/download_tip_btn@2x.png')"
      />
    </div>
  </div>
</template>

<script>
import {
  commentList,
  livingDetail,
  fansFollow,
  // watchDuration,
  liveBalanceLottery,
  updateAnchorName,
  getCompanyInfo,
  liveWatch,
  // liveWatchCount,
  liveWatchData,
  // liveLeave,
} from '@/api/livebroadcast';
import { ref } from 'vue';
import { QNRTPlayer } from 'qn-rtplayer-web';
import companyShare from '@/components/CompanyShare';
import download from '@/composables/download';
import { Notify, Dialog } from 'vant';

export default {
  setup() {
    const { openApp, browser } = download();
    const showDownload = ref(false);
    const toApp = () => {
      console.log(browser);
      if (browser.versions.weixin) {
        showDownload.value = true;
      } else {
        openApp('student');
      }
    };
    return {
      isWeixin: browser.weixin,
      toApp,
      showDownload,
      hideShowDownload: () => {
        showDownload.value = false;
      },
    };
  },
  data() {
    return {
      salarylist: {
        1: '全部',
        2: '3k以下',
        3: '3k-5k',
        4: '5k-10k',
        5: '10k-20k',
        6: '20k-50k',
        7: '50k以上',
      },
      educationalBackgroundlist: {
        1: '全部',
        2: '专科',
        3: '本科',
        4: '硕士',
        5: '博士',
      },
      scalelist: {
        1: '10人以下',
        2: '10~20人',
        3: '20~50人',
        4: '50~200人',
        5: '200~1000人',
        6: '1000以上',
      },
      form: {
        value: '',
      },
      activeName: 'first',
      activeNameTwo: 'second',
      dialogVisible: false,
      nameFormVisible: false,
      nameForm: {
        value: '',
      },
      commentList: [],
      liveData: {
        liveRoomName: '',
        rtmpplayURL: '',
      },
      commentParams: {
        commentType: 1,
        pageNum: 1,
        pageSize: 10,
        sourceId: 0, // 被评论内容 id
      },
      total: 0,
      count: 0,
      loading: true,
      companyInformation: {},
      positionInformation: {},
      preStatus: 0,
    };
  },
  watch: {
    'commentParams.pageNum': 'getCommentList',
  },
  components: {
    CompanyShare: companyShare,
  },
  computed: {
    userId() {
      return this.$route.query.userid;
    },
    isAnchor() {
      return this.userId === this.liveData.userId;
    },
  },
  async mounted() {
    // 1. 创建 QNRTPlayer 对象
    const player = new QNRTPlayer();
    // 2. 初始化配置信息
    player.init({
      controls: true,
      playsinline: true,
      width: '100%',
      height: '212px',
    });

    player.on('error', (error) => {
      // ...
      console.log(error);
    });

    this.player = player;
    await this.getDetailData();
    // this.getCommentList();

    // this.addComment('进入直播间');

    // this.getProfessionList();
    // this.queryComHome();
    const { liveAnchorLiveId } = this.liveData;
    if (!liveAnchorLiveId) return;
    window.addEventListener('beforeunload', this.beforeunload);
    liveWatch({
      liveAnchorLiveId,
    });
  },
  beforeUnmount() {
    // this.addComment('离开直播间');
    clearInterval(this.timer);
    this.player.stop();
    this.stopListenerComment();
    const { liveAnchorLiveId } = this.liveData;
    window.removeEventListener('beforeunload', this.beforeunload);
    if (!liveAnchorLiveId) return;
    this.addComment('离开直播间');
    // liveLeave({
    //   liveAnchorLiveId,
    // });
  },
  methods: {
    beforeunload() {
      // const { liveAnchorLiveId } = this.liveData;
      // liveLeave({
      //   liveAnchorLiveId,
      // });
    },
    handleClick() {
      this.activeNameTwo = this.activeName === 'second' ? 'first' : 'second';
    },
    handleClickTwo() {
      this.activeName = this.activeNameTwo === 'first' ? 'second' : 'first';
    },
    comSplit(name) {
      const namelist = name.split('|');
      return namelist;
    },
    queryComHome() {
      const userId = this.$route.query.userid
        ? this.$route.query.userid
        : '1445582247326060545'; //  // 1445582247363809300
      getCompanyInfo({ userId: userId }).then((response) => {
        const result = response.data.result;
        if (result) {
          this.companyInformation = result;
          // this.getProfessionList();
        }
      });
    },
    // getProfessionList() {
    //   const userid = this.$route.query.userid
    //     ? this.$route.query.userid
    //     : '1445582247326060545';
    //   getProfessionList({ pageNum: 1, shelf: 1, userId: userid }).then(
    //     (response) => {
    //       const result = response.data.result;
    //       if (result) {
    //         this.positionInformation = result.records;
    //       }
    //     }
    //   );
    // },
    handleEditName() {
      this.nameForm.value = this.liveData.liveRoomName;
      this.nameFormVisible = true;
    },
    async handleNameOk() {
      const { value } = this.nameForm;
      if (!value || !value.trim()) {
        Notify({ type: 'warning', message: '不能为空！' });

        return;
      }
      const res = await updateAnchorName({
        liveAnchorId: this.$route.params.id,
        liveRoomName: value.trim(),
      });
      if (res.data.code === '200') {
        this.liveData.liveRoomName = value.trim();
        this.nameFormVisible = false;
      }
    },
    handleSendEDou(comment) {
      this.form.audienceUserId = comment.userId;
      this.form.value = '';
      this.dialogVisible = true;
    },
    async handleSendEDouOk() {
      const { value, ...rest } = this.form;
      console.log(value);
      const res = await liveBalanceLottery({
        amount: value,
        liveAnchorId: this.$route.params.id,
        liveId: this.liveData.liveAnchorLiveId,
        ...rest,
      });
      if (res.data.code === '200') {
        Notify({ type: 'success', message: '赠送成功' });

        this.dialogVisible = false;
      }
    },
    startListenerComment() {
      this.stopListenerComment();
      this.commentTimer = setInterval(() => {
        // this.getCommentList();
        this.getOnlineDetail();
      }, 6000);
    },
    stopListenerComment() {
      clearInterval(this.commentTimer);
    },
    async setWatchDuration() {
      // if (!this.liveData.liveAnchorLiveId) return;
      // try {
      //   const response = await watchDuration({
      //     liveId: this.liveData.liveAnchorLiveId,
      //     userId: this.userId,
      //   });
      //   if (response.data.code == 200) {
      //     console.info(response.data.result);
      //   }
      // } catch (err) {
      //   console.info(err);
      // }
    },
    async getDetailData() {
      try {
        const response = await livingDetail({
          //   liveAnchorId: 27,
          liveAnchorId: this.$route.query.liveAnchorId,
          userId: this.$route.query.userid,
        });
        if (response.code == 200) {
          this.liveData = response.result;
          this.$refs.container.innerHTML = '';
          this.setWatchDuration();
          this.getOnlineDetail();
          this.startListenerComment();
          this.timer = setInterval(this.setWatchDuration, 10000);
          this.player.play(
            response.result.rtmpplayURL, // 测试链接
            this.$refs.container
          );
        }
      } catch (err) {
        Dialog.alert({
          title: '提示',
          message: '直播已结束',
        });
      } finally {
        this.loading = false;
      }
    },
    queryFansFollow() {
      if (this.liveData.followTag) return;
      fansFollow({ liveAnchorId: this.$route.params.id }).then((data) => {
        const date = data.data.result;
        if (date) {
          //   this.$message({
          //     showClose: true,
          //     message: '',
          //     type: 'success',
          //   });
          Notify({ type: 'success', message: '关注成功！' });

          this.liveData.followTag = true;
        }
      });
    },
    async getCommentList() {
      try {
        if (this.commentParams.pageNum === 1) {
          this.startListenerComment();
        } else {
          this.stopListenerComment();
        }
        const response = await commentList({
          ...this.commentParams,
          sourceId: this.liveData.liveAnchorLiveId,
        });
        if (response.code === '200') {
          const result = response.result;
          this.total = result.total;
          this.commentList = result.records;
          // this.commentList = fakeComment(10)
        }
      } catch (err) {
        // Notify({ type: 'danger', message: 'err' });
      }
    },
    async getOnlineDetail() {
      if (!this.liveData.liveAnchorLiveId) return;
      /**
       *   NOT_LIVING(1, "未开播"),
    LIVING(2, "直播中"),
    LEAVE(3, "离开"),
    END(4, "下播")

       */
      try {
        const res = await liveWatchData({
          liveAnchorLiveId: this.liveData.liveAnchorLiveId,
        });
        console.log('rest', res);
        if (res.code === '200') {
          this.count = res.result.watchCount || 0;
          const status = res.result.liveStatus;
          if (status === this.preStatus) return;
          if (status === 3) {
            await Dialog.alert({
              title: '提示',
              message: '主播暂时离开',
            });
          }
          if (status === 4) {
            await Dialog.alert({
              title: '提示',
              message: '主播已下播',
            });
          }
          this.preStatus = status;
        }
      } catch (err) {
        // this.stopListenerComment();
      }
    },
    // async addComment(content) {
    //   return commentCreate({
    //     commentType: 1,
    //     content,
    //     sourceId: this.liveData.liveAnchorLiveId,
    //     userId: this.userId,
    //     userRoleType: 1
    //   });
    // }
  },
};
</script>

<style lang="scss" scoped>
.share-wrapper {
  padding-bottom: 100px;
}
.popper-class {
  padding: 8px !important;
  min-width: 100px !important;
}
.div_tle {
  padding: 20px 10px;
  border-bottom: 1px solid #efe8e8;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 7%);
  border-radius: 16px;
  padding: 20px 38px;
}
.div_content {
  display: flex;
  flex: 1;
  font-size: 30px;
}
.icon_img {
  height: 50px;
  width: 50px;
}
.icon_font {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 63px;
  margin-left: 10px;
}
.font_color {
  height: 52px;
  font-size: 38px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ec2929;
  line-height: 52px;
  text-align: right;
  text-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
}
.div_title {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
}
.font_title {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 50px;
}
.font_content {
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  padding: 18px 0;
}
.homesharing {
  .el-tabs__active-bar {
    background-color: #f5e929;
  }
  .title_left {
    margin: 10px 5% 20px 20%; /* width: 128px; */
    /* height: 44px; */
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 1.5;
  }
  .title_right {
    margin: 10px 20% 20px 5%;
    /* width: 128px; */
    /* height: 44px; */
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 1.5;
  }
  .name_span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 50px;
    text-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
    padding-bottom: 20px;
    display: inline-block;
  }
  .el-tabs__item {
    font-size: 32px !important;
    padding: 0px 40px !important;
    height: 80px !important;
    line-height: 80px !important;
    font-family: PingFangSC-Regular, PingFang SC !important;
    font-weight: 800 !important;
    color: #4b4b4b !important;
  }
  .pad {
    padding-right: 40px;
    font-size: 20px;
  }
  .el-tab-pane {
    padding: 15px 20px;
  }
}
</style>
<style lang="scss" scoped>
.city_span {
  background: rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 7%);
  border-radius: 4px;
  display: inline-block;
  padding: 4px 14px;
  margin-right: 20px;
}
.editIcon {
  margin-left: 4px;
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 16px;
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
}

.count-wrapper {
  font-size: 16px;
  font-weight: bold;
  // position: absolute;
  // top: 20px;
  // right: 10px;
}

.container {
  display: flex;
  justify-content: center;
  // min-height: calc(100vh - 200px);
}

.no-video {
  width: 100%;
  height: 30vh;
  min-height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.comment-wrapper {
  // margin: 24px 0;
  background-color: #fff;

  .comment-header {
    padding-bottom: 12px;
    border-bottom: 1px solid #ddd;
  }

  .title {
    font-size: 16px;
    position: relative;
    padding-left: 16px;
    font-weight: bold;

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: rgb(255, 42, 54);
      border-radius: 2px;
    }
  }

  .comment-body {
    .comment-item {
      padding: 8px 16px;
      margin-top: 6px;
      line-height: 25px;
      border-bottom: 1px solid #f6f6f6;
    }

    .comment-item-meta {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .comment-time {
      font-size: 14px;
      color: #8590a6;
    }

    .comment-user {
      font-weight: bold;
    }
  }

  .empty {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.bottom_wrap {
  height: 155px;
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  bottom: 0px;
  left: 0px;
  border-radius: 12px 12px 0px 0px;
  background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
  z-index: 1000;
  .bottom_top {
    height: 77px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px 12px 0px 0px;
    color: #6464cd;
    font-size: 14px;
    line-height: 1;
  }
  .bottom_bottom {
    display: flex;
    justify-content: center;
    height: 100%;
    .btn {
      width: 280px;
      height: 40px;
      text-align: center;
      background: linear-gradient(90deg, #9d9de8 0%, #6464cd 100%);
      line-height: 40px;
      border: 1px solid #f2f2f2;
      font-weight: 500;
      border-radius: 22px;
      color: #fff;
      margin-top: 12px;
    }
  }
}

.yindao {
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  .yindao_img {
    position: absolute;
    right: 16px;
    top: 0px;
    width: 55%;
  }
}
</style>
