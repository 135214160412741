<template>
  <div class="company-share">
    <div class="head">
      <div class="card">
        <div class="name-block">
          <yxz-avatar
            class="left border-image"
            :src="info.logo"
            :defaultSrc="require('@/assets/images/fault-ava.png')"
          />
          <div class="right">
            <div class="name-wrapper">
              <span class="van-multi-ellipsis--l3">{{ info.name }}</span>
            </div>

            <div class="info-wrapper">
              <span class="van-ellipsis" v-if="info.indName">{{
                info.indName
              }}</span>
              <span class="van-ellipsis" v-if="info.natureName">{{
                info.natureName
              }}</span>
              <span class="van-ellipsis" v-if="companyScale(this.info.scale)">{{
                companyScale(this.info.scale)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="show" v-if="checked.src">
      <div class="shown">
        <video
          v-if="checked.type === 'video'"
          class="shown-inner"
          controls
          preload="auto"
          :poster="videoPoster"
          style="object-fit: contain"
          :src="checked.src"
        >
          <source :src="checked.src" type="video/mp4" />
        </video>

        <van-image
          v-else
          class="shown-inner"
          :src="checked.src"
          fit="contain"
        />
      </div>
    </div>

    <div class="bar" v-if="checked.src">
      <div
        class="item video"
        :class="{
          active: index < 0,
        }"
        @click="checkVideo"
        v-if="info.video"
      >
        <van-image :src="videoPoster" />
      </div>

      <div
        class="item"
        :class="{
          active: index == i,
        }"
        v-for="(item, i) in info.companyImages"
        :key="item.id"
        @click="checkImage(i)"
      >
        <van-image :src="item.url"></van-image>
      </div>
    </div>

    <van-tabs sticky animated swipeable lazy-render @change="changeTab">
      <van-tab title="在招职位">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="已经到底了"
          @load="getProfessions"
        >
          <div
            class="profession-card"
            v-for="item in professions"
            :key="item.id"
            :class="{ hot: item.hot }"
            @click="toJobShare(item)"
          >
            <div class="name-wrapper">
              <div class="left">
                <span class="van-ellipsis">{{ item.name }}</span>
                <van-image
                  width="51"
                  height="22"
                  :src="require('@/assets/images/hot.png')"
                  v-if="item.hot"
                ></van-image>
              </div>

              <span class="right">{{ salaryText(item.salary) }}</span>
            </div>

            <div class="tag-wrapper">
              <div class="tags">
                <div class="tag">{{ item.cityName }}</div>
                <div class="tag">{{ eduText(item.educationalBackground) }}</div>
              </div>

              <van-image
                width="14"
                height="17"
                v-if="item.isReward"
                :src="require('@/assets/images/red-envelope.png')"
              >
              </van-image>
            </div>
          </div>
        </van-list>

        <div class="bg"></div>
      </van-tab>

      <van-tab title="公司简介">
        <div class="introduce">
          <div class="title">
            <van-image
              width="17"
              height="17"
              :src="require('@/assets/images/company.png')"
            ></van-image>
            <span>公司介绍</span>
          </div>

          <div class="detail" :style="{ height }">
            <div class="detail-content" v-html="introduce || '暂无'"></div>
          </div>

          <div
            class="toggle flex middle center"
            v-if="introduce"
            @click="toggleDetail"
          >
            <van-icon :name="isHide ? 'arrow-down' : 'arrow-up'" />
            {{ isHide ? '展开' : '收起' }}
          </div>

          <div class="title">
            <van-image
              width="16"
              height="18"
              :src="require('@/assets/images/address.png')"
            ></van-image>
            <span>工作地点</span>
          </div>

          <div class="text">{{ info.address || '暂无' }}</div>

          <div class="title">
            <van-image
              width="17"
              height="17"
              :src="require('@/assets/images/global.png')"
            ></van-image>
            <span>公司官网</span>
          </div>

          <div class="text">
            <a v-if="info.webSite" :href="website" target="_blank">{{
              info.webSite
            }}</a>

            <span v-else>暂无</span>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Notify, Image, Icon, Tab, Tabs, List } from 'vant';

import { getComInfo, getProfessionList } from '@/api/api';
import YxzAvatar from '@/components/Avatar';
import wxShare from '@/composables/wx';
import filters from '@/composables/filters';

export default {
  name: 'CompanyShare',

  setup() {
    const { wxShareHandler } = wxShare();
    const { salaryText, eduText } = filters();

    return {
      wxShareHandler,
      salaryText,
      eduText,
    };
  },
  props: ['companyId', 'liveAnchorLiveId'],
  data() {
    return {
      info: {},
      professions: [],
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      checked: {
        src: '',
        type: '',
      },
      index: 0,
      isHide: true,
      height: '0',
    };
  },

  computed: {
    videoPoster() {
      return (
        this.info.companyVideoCover ||
        require('@/assets/images/video-poster.png')
      );
    },

    introduce() {
      return (this.info.intruduce || '')
        .replace(/</gi, '&lt;')
        .replace(/>/gi, '&gt;')
        .replace(/[\r\n]/gi, '<br/>')
        .replace(/ /gi, '&nbsp;');
    },

    website() {
      if (/^https?:\/\//.test(this.info.webSite)) {
        return this.info.webSite;
      }

      return this.info.webSite ? 'http://' + this.info.webSite : '#';
    },
  },

  created() {
    if (!this.companyId) {
      return Notify('链接已损坏，请重新分享打开');
    }
    this.getInfo();
    this.getProfessions();
  },

  methods: {
    toJobShare(item) {
      const { liveAnchorLiveId } = this;
      let suffix = '';
      if (liveAnchorLiveId) {
        suffix = `&liveAnchorLiveId=${liveAnchorLiveId}`;
      }
      this.$router.push(`/jobShare?id=${item.id}${suffix}`);
    },
    getInfo() {
      getComInfo({
        params: {
          companyId: this.companyId,
        },
        loading: true,
      }).then((res) => {
        this.info = res;
        this.$nextTick(this.calcHeight);

        this.wxShareHandler({
          title: res.name,
          url: window.location.href,
          icon: 'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/191598693566_.pic_hd.jpg',
          desc: res.indName,
        });

        if (this.info.video) {
          this.checkVideo();
        } else {
          this.checkImage(0);
        }
      });
    },

    getProfessions() {
      const current = this.pageNum;
      getProfessionList({
        params: {
          companyId: this.companyId,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (this.pageNum >= res.pages) {
          this.finished = true;
        }

        console.log(current, '----');
        if (current === 1) {
          this.professions = res.records;
        } else {
          this.professions = this.professions.concat(res.records);
        }
        this.pageNum += 1;
        this.loading = false;
        this.total = res.total;
      });
    },

    checkVideo() {
      this.index = -1;
      this.checked = {
        src: this.info.video,
        type: 'video',
      };
    },

    checkImage(index) {
      this.index = index;

      if ((this.info.companyImages || []).length <= index) {
        return;
      }

      this.checked = {
        src: this.info.companyImages[index].url,
        type: 'image',
      };
    },

    companyScale(val) {
      return (
        [
          '',
          '10人以下',
          '10-20人',
          '20-50人',
          '50-200人',
          '200-1000人',
          '1000人以上',
        ][val] || ''
      );
    },

    toggleDetail() {
      this.isHide = !this.isHide;
      this.calcHeight();
    },

    calcHeight() {
      const height = document.querySelector('.detail-content').scrollHeight;

      if (this.isHide) {
        this.height = height < 134 ? height + 'px' : '102px';
      } else {
        this.height = height + 'px';
      }
    },

    changeTab() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },

  components: {
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    YxzAvatar,
  },
};
</script>

<style lang="scss" scoped>
.company-share {
  background: #fafaf7;
  min-height: 100vh;

  .head,
  .show,
  .bar,
  :deep(.van-tabs__wrap) {
    background: #fff;
  }
}

.card {
  background: #fff;
  border-radius: 8px;
  padding: 10px;
}

.border-image :deep(img) {
  border-radius: 4px;
}

.name-block {
  display: flex;

  .left {
    flex: 0 0 45px;
    width: 45px;
    height: 45px;
  }

  .right {
    margin-left: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .name-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        flex: 1;
        font-size: 18px;
      }

      .van-image {
        margin-left: 10px;
        flex: 0 0 63px;
        width: 63px;
        height: 18px;
      }
    }

    .info-wrapper {
      font-size: 10px;
      color: #666666;
      display: flex;
      overflow: hidden;

      span {
        display: inline-block;
        margin-right: 20px;
        max-width: 40%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.show {
  margin: 0 10px;
  height: 0;
  padding-top: 56%;
  position: relative;

  .shown {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .shown-inner {
      width: 100%;
      height: 100%;
    }
  }
}

.bar {
  display: flex;
  padding: 10px;
  overflow-x: auto;

  .item {
    flex: 0 0 96px;
    width: 96px;
    height: 54px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    border: 2px solid transparent;

    + .item {
      margin-left: 5px;
    }

    &.active {
      border-color: #f7e934;
    }

    &.video:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url('~@/assets/images/video-mask.png') no-repeat;
      background-size: cover;
    }
  }
}

:deep(.van-tabs__nav) {
  margin: 0 20%;

  .van-tab__text {
    font-size: 16px;
  }

  .van-tabs__line {
    height: 8px;
    background: transparent;
  }

  .van-tabs__line:after {
    content: '';
    position: absolute;
    left: 10px;
    margin-top: -2px;
    width: 20px;
    height: 5px;
    border-bottom: 2px solid #333;
    border-radius: 0 0 50% 50%;
  }
}

:deep(.van-tabs__wrap) {
  padding-bottom: 10px;
}

.introduce {
  padding: 10px;
  background: #fff;

  .title {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .van-image {
      margin-right: 5px;
    }
  }

  .detail {
    margin-top: 10px;
    overflow: hidden;
    height: 102px;
    font-size: 14px;
    color: #666;
    transition: all 0.5s ease-in-out;
  }

  .toggle {
    margin-top: 18px;
    color: #333;
    text-align: center;
    cursor: pointer;
    font-size: 14px;

    .van-icon {
      margin-right: 2px;
    }
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    color: #666;

    a {
      color: inherit;
    }
  }
}

.profession-card {
  margin: 10px;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.07);
  background: #fff;

  &.hot {
    background: linear-gradient(270deg, #fffdf2 0%, #fffffb 100%);
  }

  .name-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    .left {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-right: 10px;

      .van-image {
        flex: 0 0 auto;
      }
    }

    .right {
      flex: 0 0 auto;
      color: #ec2929;
    }
  }

  .tag-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .tags {
      display: flex;
      overflow: hidden;

      .tag {
        margin-right: 5px;
        display: inline-block;
        padding: 2px 7px;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.03);
        color: #666;
        border-radius: 2px;
        white-space: nowrap;
      }
    }

    .van-image {
      margin-left: 10px;
      flex: 0 0 auto;
    }
  }
}
</style>
